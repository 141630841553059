import {useEffect} from "react"
import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";
import {ModelManager} from "../../lib/html5story/ModelManager.ts";

// localhost:3000/ARFind?item=1
// 테스트
const ARFind = (({location, match}) => {
  
  useEffect(() =>  {
      
    
      let item = ModelManager.instance().getData("target");

      if (item !=null && item!="")
      {
        ModelManager.instance().postAttendance();
      }

      /// test source
      const urlParameter = window.location.search;
      const urlParams = new URLSearchParams(urlParameter);
      let itemUrl = urlParams.get("item");

      if (itemUrl!=""&&itemUrl!=null&&itemUrl!=undefined)  
      {
          item =itemUrl
          ModelManager.instance().setData("target",item);
          //ModelManager.instance().setData("carditems","1:2:"+item);
      }

      var dic = {};
      dic['1'] =  SCENE.MOVIEITEM1;
      dic['2'] =  SCENE.MOVIEITEM2;
      dic['3'] =  SCENE.MOVIEITEM3;
      dic['4'] =  SCENE.MOVIEITEM4;
      dic['5'] =  SCENE.MOVIEITEM5;
      dic['6'] =  SCENE.MOVIEITEM5;
      dic['7'] =  SCENE.MOVIEITEM5;
      dic['8'] =  SCENE.MOVIEITEM5;
      


      
      SceneManager.instance().Init( dic[item] );
  
  },[])

  return (
  <>
  </>
)
})

export {ARFind}