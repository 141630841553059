import {useEffect} from "react"
import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";
import {ModelManager} from "../../lib/html5story/ModelManager.ts";

const Home = (({location, match}) => {
  
  useEffect(() =>  {

    console.log("20220325");
    ModelManager.instance().init();

    ModelManager.instance().setTarget(1);

     //ModelManager.instance().setData("carditems","1:2:3:4");
     ModelManager.instance().setData("items",":::");

     ////

      
      SceneManager.instance().Init( SCENE.ZONE1 );
      //SceneManager.instance().Init( SCENE.MOVIE2 );
    

  },[])

  return (
  <>
  </>
)
})

export {Home}

