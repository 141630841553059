import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import {Scene} from "./Scene.ts";
import {Config} from "./Config.ts";

class SceneLoading extends Scene
{
    constructor() 
    {
        super();

        let text = new PIXI.Text("Loading...", {
            fontFamily: "NanumBarunGothic",
            //fontWeight:"bold",
            fontSize: 50,
            fill: "#000000",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0
        });
       
        text.anchor.set(0.5);
        text.x = Config.SCREEN_WIDTH/2;
        text.y = Config.SCREEN_HEIGHT/2;
        super.addChild(text);


    }
		
    

} 

export {SceneLoading}
