import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { TextBox } from "./TextBox.ts";
import { PopupErrorZone } from "./PopupErrorZone.ts";
import { ColorButton } from "./ColorButton.ts";
class SceneItem2 extends Scene
{

  constructor() 
  {
      super();

      let bg = new PIXI.Graphics();
      bg.beginFill(0xffffff);
      bg.drawRect(0,0,Config.SCREEN_WIDTH,Config.SCREEN_HEIGHT);
      bg.endFill();
      
      super.addChild(bg);

      
      let icon_close = new Button("",30 );
      icon_close.x = Config.SCREEN_WIDTH - 100/2;
      icon_close.y = 109/2;
      icon_close.alpha = 1;
      super.addChild(icon_close);
    
      // let image_01_copy=new Image("icon_close.png");
    
      // image_01_copy.x = 0;
      // image_01_copy.y = 0; 
      
      // icon_close.addChild(image_01_copy);
    
      // icon_close.on('pointerup', ()=>
      // {
      //   SceneManager.instance().SetScene(SCENE.ITEM1);
      // });
      
      let msg = "그러면 나에 대한 이야기가 담긴\n스티커 조각들을 하나씩 찾아봐!";
      
      let TextBox1= new TextBox(msg, 34, false);
      //TextBox1.x = Config.SCREEN_WIDTH/2;
      TextBox1.x = 80;
      TextBox1.y = 100 + 234/2;
    
      super.addChild(TextBox1);
    
    
    
      // let map_dummy=new Image("g6190.png");
    
      // map_dummy.x = Config.SCREEN_WIDTH/2;
      // map_dummy.y = Config.SCREEN_HEIGHT/2; 
      
      // super.addChild(map_dummy);
    

      let map_dummy=new Image("g6144.png");

      map_dummy.x = Config.SCREEN_WIDTH/2-6;
      map_dummy.y = Config.SCREEN_HEIGHT/2; 
      
      super.addChild(map_dummy);
    
      let i34=new Image("i34.png");
    
      i34.x = Config.SCREEN_WIDTH/2;
      i34.y = Config.SCREEN_HEIGHT/2+100; 
      i34.MoveSin(2,20);
      super.addChild(i34);
      
    
       let button = new ColorButton("응 알았어!! 찾아볼께",30 );
       button.x = Config.SCREEN_WIDTH/2;
       button.y = Config.SCREEN_HEIGHT -80-52;
       
       super.addChild(button);
    
       button.on('pointerup', ()=>
       {
          ModelManager.instance().setTarget(1);
          SceneManager.instance().SetScene(SCENE.NOWITEM); // 
          //SceneManager.instance().SetScene(SCENE.TARGET); // 
          
       });
    

  }
} 

export {SceneItem2}
