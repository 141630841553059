import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import { sound } from '@pixi/sound';
import {DataManager} from "./DataManager.ts";

class ColorButton extends PIXI.Container 
{
    darkImage:PIXI.Graphics;
    textArea : PIXI.Text;

    bg : PIXI.NineSlicePlane;


    constructor(msg:string, s:number = 40, ww:number = 560, hh:number = 104, color:number =0x51ba78 ) {

        super();
        
        super.interactive = true;
        super.buttonMode = true;
        
        super.on('pointerdown', this.onClickDown);
        super.on('pointerup', this.onClickUp);
        super.on('pointerupoutside', this.onClickUp);
      
        this.UpdatePlane(msg,s, ww, hh, color );
     }

     public UpdatePlane(text:string, s:number = 30, ww:number = 560, hh:number = 104, color:number =0x51ba78 ): void 
    {
        let splitted = text.split("\n"); 

        let w= ww;
        let h= hh;
        this.width= w;
        this.height=  h;

        this.darkImage = new PIXI.Graphics();
        this.darkImage.beginFill(color);
        this.darkImage.drawRect(-w/2, -h/2, w, h);
        this.darkImage.endFill();
        
        super.addChild(this.darkImage);

         this.textArea = new PIXI.Text(text, {
         fontFamily: "NanumBarunGothics",
         fontSize: s,
         fill: "#ffffff",
         stroke: "black",
         strokeThickness: 0,
         letterSpacing : 0,
         align : 'center'
         });
   
         this.textArea.anchor.set(0.5);
         this.textArea.x= 0;
         this.textArea.y= 0;
         super.addChild(this.textArea);
         this.Scale();
     }

      public SetText(msg:string, s:number = 40):void
      {
         super.removeChild(this.textArea);
         super.removeChild(this.darkImage);

         this.UpdatePlane(msg,s);
      }

     public DelayActive(t:number)
     {
        super.visible =false;
        //super.visible =true;
        setTimeout(() => {super.visible =true;this.Scale2()}, t);
     }
  
     // 좁더 세개 쿨ㄹㅇ
     public DelayActive2(t:number)
     {
        super.visible =false;
        //super.visible =true;
        setTimeout(() => {super.visible =true;this.Scale()}, t);
     }

     public onClickDown():void
     {
        super.scale.x = 1.25;
        super.scale.y = 1.25;
     }
    
     public onClickUp():void
     {
        super.scale.x = 1;
        super.scale.y = 1;

        sound.play('click');
     }

     public Scale(): void 
     {
         super.scale.x = 0.01;
         super.scale.y = 0.01;
 
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic' });
     }

     public Scale2(): void 
     {
         super.scale.x = 0.01;
         super.scale.y = 0.01;
 
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 300, ease: 'easeOutBack' });
         
     }
} 


export {ColorButton}

