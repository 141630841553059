import {Link} from "react-router-dom"



const base = ""

const Result = (({location, match}) => (
  <div style={{
    backgroundImage: "url('/assets/farmer.png')",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }}>
    <div style={{
      padding: '25px 0'
    }}>
      <div style={{
        background: 'white',
        width: '70%',
        margin: '0 auto',
        border: '1px solid #000',
        padding: '10px',
        textAlign: 'center'
      }}>
      찾아줬구나! 고마워!<br/>
      우리는 이 <b>돌칼</b> 로 <span style={{color: '#03a9f4'}}>곡물을 수확해</span>.<br/>
      키가 들쑥날쑥한 작물을 수확하는데에는 이만한 게 없지!<br/>
      덕분에 오늘 일을 할 수 있겠어!<br/>
      정말 고마워!!
      </div>
    </div>

    <div
      className={'hints'}
      style={{
        padding: '25px',
        position: 'fixed',
        bottom: '100px',
      }}
    >
      <div
        className={'hints-image'}
        style={{
          width: '120px',
          height: '120px',
          borderRadius: '50%',
          background: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            width: '70px',
            height: '70px',
            objectPosition: 'center',
            objectFit: 'contain',
          }}
          src={'/assets/clay-pot.jpg'}
          alt="target"
        />
      </div>
    </div>
    
    <Link to={`${base}/`}
       className={``}
       style={{
          width: '90%',
          position: 'fixed',
          left: '5%',
          bottom: '10px',
          background: '#03a9f4',
          display: 'block',
          color: '#fff',
          textDecoration: 'none',
          padding: '5px',
          textAlign: 'center',
          letterSpacing: '5px',
       }}
    >
      별말씀을! 찾아서 다행이다
    </Link>
  </div>
))

export {Result} 