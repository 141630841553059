import * as PIXI from "pixi.js";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { ColorButton } from "./ColorButton.ts";

import { Image } from "./Image.ts";
import { Card} from "./Card.ts";
import {Config} from "./Config.ts";
import { Ease, ease } from "pixi-ease"
import { SceneManager, SCENE } from "./SceneManager.ts";
import { sound } from '@pixi/sound';
import { ModelManager } from "./ModelManager.ts";

class SceneNextItem extends Scene
{
    public image_target : Image ;

    public card0:Card; 
    public card1:Card;
    public card2:Card;
    public card3:Card;

    constructor() 
    {
        super();

        sound.play('bgm',{ loop:true, volume:0.3 });
        

        let itemList = ModelManager.instance().GetItem();

        let target = ModelManager.instance().getData("target");
        let last = false;
        let first = false;
        let nexttarget=parseInt(target)+1;
        switch(target)
        {
            case '8':
                itemList=["7","8",""]
                last =true;
                break;
            case '1':
                itemList=["","1","2"]
                first =true;
                break;
            case '2':
                itemList=["1","2","3"]
                break
            case '3':
            itemList=["2","3","4"]
            break
            case '4':
            itemList=["3","4","5"]
            break
            case '5':
            itemList=["4","5","6"]
            break
            case '6':
            itemList=["5","6","7"]
            break
            case '7':
            itemList=["6","7","8"]
            break
        }
        
        let  bg2 = new Image("image-bg.png", true);
        bg2.x = Config.SCREEN_WIDTH/2;
        bg2.y = Config.SCREEN_HEIGHT/2; 

        // let scale1= Config.SCREEN_WIDTH /Config.SCREEN_HEGITH;;
        // let scale2= window.innerWidth / window.innerHeight;

        // if( scale2 > scale1 )
        // {
        //     bg2.scale.x = scale2 / scale1;
        // }

        super.addChild(bg2);

        let title = new Image("image-title.png");
        title.x = Config.SCREEN_WIDTH/2;
        title.y = 156 + 142/2; 
        title.Scale();
        super.addChild(title);


        let group = new PIXI.Container();
        

        if (first==false)
        {

            let image_path = new Image("image-path.png");
            image_path.x = Config.SCREEN_WIDTH/4;
            image_path.y = Config.SCREEN_HEIGHT/2;
            image_path.rotation = -15;
            
            group.addChild(image_path);

        }
        

        if (last==false)
        {
        let image_path2 = new Image("image-path.png");
        image_path2.x = Config.SCREEN_WIDTH/4+Config.SCREEN_WIDTH/2;
        image_path2.y = Config.SCREEN_HEIGHT/2;
        
        group.addChild(image_path2);
        }


        if (last==false)
        {
            let image_path3 = new Image("image-path.png");
            image_path3.x = Config.SCREEN_WIDTH/4+Config.SCREEN_WIDTH;
            image_path3.y = Config.SCREEN_HEIGHT/2;
            image_path3.rotation = -15;
            group.addChild(image_path3);
    
        }


///////////////////
        ////////////
        this.card0 = new Card(Config.GetTitleNext(itemList[0]), Config.GetImageNext(itemList[0]));
        this.card0.x = 0;
        this.card0.y = Config.SCREEN_HEIGHT/2; 

        if (itemList[0]=="" || first==true)
        {
            this.card0.visible=false;
        }

        this.card0.open();
        
        group.addChild(this.card0);

        this.card1 = new Card(Config.GetTitleNext(itemList[1]),Config.GetImageNext(itemList[1]));
        if (itemList[1]=="")
        {
            this.card1.visible=false;
        }
        this.card1.x = Config.SCREEN_WIDTH/2;
        this.card1.y =Config.SCREEN_HEIGHT/2; 
        this.card1.openning();
        group.addChild(this.card1);


        this.card2 = new Card(Config.GetTitleNext(itemList[2]),Config.GetImageNext(itemList[2]));

        if (itemList[2]=="" || last==true)
        {
            this.card2.visible=false;
        }
        
        this.card2.x = Config.SCREEN_WIDTH;
        this.card2.y =Config.SCREEN_HEIGHT/2; 
        this.card2.lock();
        group.addChild(this.card2);

        this.card3 = new Card("","image-circle-blank.png");
        this.card3.x = Config.SCREEN_WIDTH/2 *3;
        this.card3.y =Config.SCREEN_HEIGHT/2; 
        this.card3.lock();

        if ( last==true)
        {
            this.card3.visible=false;
        }

        group.addChild(this.card3);
        super.addChild(group);
        

        if ( last==true)
        {
            //const eff1 = ease.add(group, { x: -Config.SCREEN_WIDTH/2 } , { duration: 1000,  wait:4000 });
            //eff1.once('complete', () => {this.card2.goTarget();this.card1.goOpen(); } );
    
        }else{
            const eff1 = ease.add(group, { x: -Config.SCREEN_WIDTH/2 } , { duration: 1000,  wait:4000 });
            eff1.once('complete', () => {this.card2.goTarget();this.card1.goOpen(); } );
        }

        //////////////

        if (last==false)
        {

            let btn_next:ColorButton = new ColorButton("다음",30,471, 74  );
            btn_next.x = Config.SCREEN_WIDTH / 2;
            btn_next.y = 900;
            btn_next.DelayActive2(7000);
    
            btn_next.on('pointerup',function()
            {

                ModelManager.instance().setTarget(nexttarget);

                SceneManager.instance().SetScene(SCENE.TARGET); // 
    
            } );   
    
            super.addChild(btn_next);
    

            let btn_nextbtn_next:ColorButton = new ColorButton("기념사진 촬영하고 SNS 자랑하기",22,471, 74, 0x9d9a9a  );
            btn_nextbtn_next.x = Config.SCREEN_WIDTH / 2;
            btn_nextbtn_next.y = 900+120;
            btn_nextbtn_next.DelayActive2(2000);

            btn_nextbtn_next.on('pointerup',function()
            {
                


            } );   

            super.addChild(btn_nextbtn_next);

        }else{
 //
                let btn_nextbtn_next:ColorButton = new ColorButton("기념사진 촬영하고 SNS 자랑하기",22,471, 74, 0x9d9a9a  );
                btn_nextbtn_next.x = Config.SCREEN_WIDTH / 2;
                btn_nextbtn_next.y = 900+120;
                btn_nextbtn_next.DelayActive2(2000);

                btn_nextbtn_next.on('pointerup',function()
                {
                    window.location.href = "https://d1w978cvy551xv.cloudfront.net/ar/photos.html";
                } );   

                super.addChild(btn_nextbtn_next);

        }

       


        // let photo_button:Image = new Image("btn-sns-off.png");
        // photo_button.x = Config.SCREEN_WIDTH / 2;
        // photo_button.y = 1000;
        // photo_button.DelayActive(7000);
   
        // photo_button.interactive = true;
        // photo_button.buttonMode = true;
     
        // photo_button.on('pointerup',function()
        // {
        //    console.log("sns");

        // } );   

        // super.addChild(photo_button);



        //sound.stopAll();
        //sound.play('bgm_item');

        sound.stopAll();
        sound.play('bgm_item');
    }

   

} 

export {SceneNextItem}
