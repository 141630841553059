import {ModelManager} from "./ModelManager.ts";

class Config {

    //public static TEST:boolean = true;

    public static get TEST():boolean {
        if (  ModelManager.instance().getData("ardebug")=="true" )
        {
            return true;
        }else{

            return false;
        }
    }

    public static get SKIP():boolean {
        if (  ModelManager.instance().getData("arskip")=="true" || ModelManager.instance().getData("ardebug")=="true" )
        {
            return true;
        }else{

            return false;
        }
    }


    private static _SCREEN_WIDTH:number = 640;
    private static _SCREEN_HEIGHT: number = 1136;
 
 
    public static get SCREEN_WIDTH():number {
          return Config._SCREEN_WIDTH;
    }
     
    public static get SCREEN_HEIGHT():number {
          return Config._SCREEN_HEIGHT;   
    }
 
    // 원본 소스 경로
    
    public static URL_LOGIN = "https://1www.smartjinju.co.kr/api/v1/login";
    public static URL_LOCATION = "https://1www.smartjinju.co.kr/api/v1/location";
    public static URL_ATTENDANCE = "https://1www.smartjinju.co.kr/api/v1/attendance/user";
 
 
    public static GET_ATTENDANCEZONE(item:string):string
    {
       var dic = {};
     
       dic['1'] = "ARTIFACT_21";
       dic['2'] = "ARTIFACT_22";
       dic['3'] = "ARTIFACT_22";
     
       return  dic[item];
    }
 
    ///////
    public static GetTitleNext(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['1'] = "북두칠성";
        dic['2'] = "강감찬 생가";
        dic['3'] = "향나무";
        dic['4'] = "굴참나무";
        dic['5'] = "호랑이 설화";
        dic['6'] = "낙성대 3층 석탑";
        dic['7'] = "개구리";
        dic['8'] = "강감찬 기마동상";

        
        return dic[item];
    }
    
    public static GetImageNext(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['1'] = "image-sticker-01.png";
        dic['2'] = "image-sticker-02.png";
        dic['3'] = "image-sticker-03.png";
        dic['4'] = "image-sticker-04.png";
        dic['5'] = "image-sticker-05.png";
        dic['6'] = "image-sticker-06.png";
        dic['7'] = "image-sticker-07.png";
        dic['8'] = "image-sticker-08.png";


        return dic[item];
    }
    
 
    
    
    
 }
 
 export {Config}
 