import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import { Image } from "./Image.ts";
import {DataManager} from "./DataManager.ts";

class Card extends PIXI.Sprite
{
    public image_eff_get:Image; // 빛
    public image_slot_check:Image ; // 배경 오픈
    public image_slot_locked:Image ; // 배경 락

    public item_dummy:Image ; // 아이템


    public image_target:Image; /// 사각형 테두리


    public icon_check_bg:Image; //
    public  image_check:Image; // 체크
    public eff_bulb_01:Image;
    public eff_bulb_02:Image;

    
    //public icon_lock:Image;

    constructor(name: string, file:string)
    {
        super();
        
        this.image_eff_get = new Image("image-eff-get.png");
        this.image_eff_get.x=0;
        this.image_eff_get.y=0;
        this.image_eff_get.Rotate();

        super.addChild(this.image_eff_get);

        this.image_slot_locked = new Image("image-circle-blank.png");
        
        this.image_slot_locked.x =0; 
        this.image_slot_locked.y =0; 
        super.addChild(this.image_slot_locked);

        this.image_slot_check = new Image("image-circle-bg-02.png");
        this.image_slot_check.x =0; 
        this.image_slot_check.y =0; 
        super.addChild(this.image_slot_check);

        this.item_dummy = new Image(file);
        this.item_dummy.x=0;
        this.item_dummy.y=-15;


        //this.item_dummy.FadeIn(2000, 1000);
        super.addChild(this.item_dummy);

        this.image_target = new Image("eff-select.png");
        this.image_target.x = 0;
        this.image_target.y = 0; 
        this.image_target.visible =false;
        super.addChild(this.image_target);


        let text = new PIXI.Text(name, {
            fontFamily: "NanumBarunGothic",
            //fontWeight:"bold",
            fontSize: 25,
            fill: "#e0ab0a",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0
        });
  
        text.y = 92; 
        text.anchor.set(0.5);
  
        super.addChild(text);

        this.eff_bulb_01 = new Image("eff-bulb-01.png");
        this.eff_bulb_01.x =0; 
        this.eff_bulb_01.y =0; 
        this.eff_bulb_01.FadeLoop(1,0);  
        this.eff_bulb_01.visible =false;
        super.addChild(this.eff_bulb_01);
        this.eff_bulb_02 = new Image("eff-bulb-02.png");
        this.eff_bulb_02.y = 0;  
        this.eff_bulb_02.FadeLoop(1,180);  
        this.eff_bulb_02.visible =false;
        super.addChild(this.eff_bulb_02);

              
        super.anchor.set(0.5);
    }

    public open():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =true;
        this.image_slot_locked.visible =false;
        this.item_dummy.visible = true;

        
        this.image_target.visible =false;
        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        

        this.scale.x = 0.8;
        this.scale.y = 0.8;
    }
    
    // 가운데 시작시 연출
    public openning():void
    {
        this.image_eff_get.visible = true;
        this.image_slot_locked.visible =true;
        this.image_slot_check.visible =true;

        this.image_slot_check.FadeIn(1000,0);

        this.item_dummy.visible = true;
        this.item_dummy.FadeIn(1000, 0);


        this.image_target.visible =false;

        
        this.scale.x = 1.0;
        this.scale.y = 1.0;
        
    }
    
    // 막힌 상ㅌ태
    public lock():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =false;
        this.image_slot_locked.visible =true;
        this.item_dummy.visible = false;
        this.image_target.visible =false;


        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        
        this.scale.x = 0.8;
        this.scale.y = 0.8;
    }
    
    // 중아오면서 커지기
    public goTarget():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =false;
        this.image_slot_locked.visible =true;
        this.item_dummy.visible = false;
        this.image_target.visible =true;
        this.image_target.FadeIn(1000,0);


        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;


        this.scale.x = 0.8;
        this.scale.y = 0.8;
        const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 1000});
    }

    // 왼쪽이동하면서 작아지기
    public goOpen():void
    {
        this.image_eff_get.visible = true;
        this.image_eff_get.FadeOut(1000);
        this.image_slot_check.visible =true;
        this.image_slot_locked.visible =false;
        this.item_dummy.visible = true;

        
        this.image_target.visible =false;
        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        

        this.scale.x = 1.0;
        this.scale.y = 1.0;
        const eff1 = ease.add(this, { scale: 0.8  } , { reverse: false, duration: 1000});
    }
    
} 


export {Card}
