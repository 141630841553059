import { useParams, useNavigate} from "react-router-dom"
import { useEffect} from "react"

import {AFrameScene} from '../../lib/aframe-components'
// import {FloatingBackButton, FloatingActionButton} from '../../lib/material-ui-components'
// import {appBase} from '../../lib/routes'
import './style.css'


const base = ''

const Scene = (({match, showTakeButton, setShowTakeButton, targetImage, setTargetImage, arSceneStart, setArSceneStart, showNotFoundPopup, setShowNotFoundPopup}) => {
  // const [showTakeButton, setShowTakeButton] = useState(false)
  const navigate = useNavigate();
  
  // const [targetImage, setTargetImage] = useState(null)

  useEffect(() => {
      return () => {
        setShowTakeButton(false)
        setTargetImage(null)
        setArSceneStart(false)
        setShowNotFoundPopup(false)
      };
  }, []);
    


  const changeTargetImage = (target = null) => {
    setTargetImage(target)
    // setShowTakeButton(!showTakeButton)
    if(target  == null){
      setShowTakeButton(false)
    } else {
      if(target.name != slug) {
        setShowNotFoundPopup(true)
      } else {
        setShowTakeButton(true)
      }
    }
  }
  const AllTargetImages = [
   {
      "key":"1",
      "value":"북두칠성",
      "image_url":"/assets/8thwall-scene-page/target-images/1.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/1.png",
      "group":"A"
   },
   {
      "key":"2",
      "value":"강감찬 생가",
      "image_url":"/assets/8thwall-scene-page/target-images/2.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/2.png",
      "group":"A"
   },
   {
      "key":"3",
      "value":"개구리 설화",
      "image_url":"/assets/8thwall-scene-page/target-images/3.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/3.png",
      "group":"A"
   },
   {
      "key":"4",
      "value":"호랑이 설화",
      "image_url":"/assets/8thwall-scene-page/target-images/4.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/4.png",
      "group":"A"
   },
   {
      "key":"5",
      "value":"향나무",
      "image_url":"/assets/8thwall-scene-page/target-images/5.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/5.png",
      "group":"A"
   },
   {
      "key":"6",
      "value":"굴참나무",
      "image_url":"/assets/8thwall-scene-page/target-images/6.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/6.png",
      "group":"A"
   },
   {
      "key":"7",
      "value":"강감찬 동상",
      "image_url":"/assets/8thwall-scene-page/target-images/7.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/7.png",
      "group":"A"
   },
   {
      "key":"8",
      "value":"3층 석탑",
      "image_url":"/assets/8thwall-scene-page/target-images/8.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/8.png",
      "group":"A"
   },
   {
      "key":"9",
      "value":"안국사",
      "image_url":"/assets/8thwall-scene-page/target-images/9.png",
      "shape_image_url":"/assets/8thwall-scene-page/target-images/9.png",
      "group":"A"
   },
  ]
  const TargetImageGroups = {
    A : ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
  }

  const {slug} = useParams()

  let expectedTargetImage = AllTargetImages.find(ti => ti.key == slug);
  
  let CurrentImageTargets = [];

  if(
    expectedTargetImage && 
    expectedTargetImage.group && 
    typeof TargetImageGroups[expectedTargetImage.group] !== 'undefined' && 
    Array.isArray(TargetImageGroups[expectedTargetImage.group])
  ) {
    CurrentImageTargets = TargetImageGroups[expectedTargetImage.group];
  }


  const myNamedImageTarget = {
    schema: {
      name: {type: 'string'},
    },
    init() {
      
      const {object3D} = this.el
      const {name} = this.data
      const geometry = {}
      object3D.visible = false

      // console.log('myNamedImageTarget component init', this)

      const checkGeometry = (newGeometry) => {
        // console.log('myNamedImageTarget component check geometry', newGeometry)
        let needsUpdate = false

        const fields = [
          'type',
          'height',
          'radiusTop',
          'radiusBottom',
          'arcLengthRadians',
          'arcStartRadians',
          'scaledWidth',
          'scaledHeight',
        ]
        fields.forEach((f) => {
          if (geometry[f] !== newGeometry[f]) {
            geometry[f] = newGeometry[f]
            needsUpdate = true
          }
        })

        if (needsUpdate) {
          this.el.emit('xrextrasimagegeometry', geometry, false)
        }
      }

      const imageScanning = ({detail}) => {
        // console.log('Image scanning', detail)
        detail.imageTargets.forEach((t) => {
          if (name !== t.name) {
            return
          }
          checkGeometry({type: t.type, ...t.geometry})
        })
      }

      const updateImage = ({detail}) => {
        // console.log('Image Update', detail)
        if (name !== detail.name) {
          return
        }
        object3D.position.copy(detail.position)
        object3D.quaternion.copy(detail.rotation)
        object3D.scale.set(detail.scale, detail.scale, detail.scale)
        object3D.visible = true
      }

      const showImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }
        
        //setShowTakeButton(true)
        
        // setTargetImage(detail)
        changeTargetImage(detail)


        checkGeometry(detail)
        updateImage({detail})
        this.el.emit('xrextrasfound', {}, false)
      }

      const hideImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }

        // setShowTakeButton(false)
        // setTargetImage(null)
        changeTargetImage(null)

        this.el.emit('xrextraslost', {}, false)
        object3D.visible = false
      }

      this.el.sceneEl.addEventListener('xrimagescanning', imageScanning)
      this.el.sceneEl.addEventListener('xrimagefound', showImage)
      this.el.sceneEl.addEventListener('xrimageupdated', updateImage)
      this.el.sceneEl.addEventListener('xrimagelost', hideImage)
    },
  }

  const configTargetsComponent = {
    schema: {
      targets: {type: 'array', default: ['']},
    },
    ensureImageTargetsConfigured() {
      if (this.configured || !this.configOk) {
        return
      }
      const instance = this;
      const imageTargetConfigure = setInterval(function(){
        if(typeof window.XR8 != 'undefined'){

          console.log(`Scanning for targets: ${JSON.stringify(instance.data.targets)}`)
          window.XR8.XrController.configure({imageTargets: instance.data.targets})
          instance.configured = true

          clearInterval(imageTargetConfigure)
        }
      }, 100)
    },
    init() {
      this.configured = false
      this.configOk = false
      this.el.sceneEl.addEventListener('realityready', () => {
        console.log('realityready')
        this.configOk = true
        this.ensureImageTargetsConfigured()
      })
    },
    update() {
      this.configured = false
      this.ensureImageTargetsConfigured()
    },
  }


  const RotateComponent = {
      schema: {
        factor: {default: 6},
      },
      init() {
        this.handleEvent = this.handleEvent.bind(this)
        this.el.sceneEl.addEventListener('onefingermove', this.handleEvent)
        this.el.classList.add('cantap')  // Needs "objects: .cantap" attribute on raycaster.
      },
      remove() {
        this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
      },
      handleEvent(event) {
        this.el.object3D.rotation.y += event.detail.positionChange.x * this.data.factor
        this.el.object3D.rotation.x += event.detail.positionChange.y * this.data.factor
      },
  }

  const myNamedImageTargetPrimitive = {
    defaultComponents: {
      'my-named-image-target': {},
    },
    mappings: {
      name: 'my-named-image-target.name',
    },
  }

  
  const checkTargetImage = () => {
    let arSuccessUrl = 'ARFind';

    const expectedTargetImageName = slug;

    if (targetImage && (expectedTargetImageName === targetImage.name)) {
      navigate(`${base}/${arSuccessUrl}`, { replace: true });
    } else {
      setShowNotFoundPopup(true)
    }
  }

  const TargetImageContainerCode = () => {
    const TargetImageContainers = {
      '1' : `<my-named-image-target name="1">
                <a-image src="#1-image"></a-image>
              </my-named-image-target>`,
      '2' : `<my-named-image-target name="2">
                <a-image src="#2-image"></a-image>
              </my-named-image-target>`,
      '3' : `<my-named-image-target name="3">
                <a-image src="#3-image"></a-image>
              </my-named-image-target>`,
      '4' : `<my-named-image-target name="4">
                <a-image src="#4-image"></a-image>
              </my-named-image-target>`,
      '5' : `<my-named-image-target name="5">
                <a-image src="#5-image"></a-image>
              </my-named-image-target>`,
      '6' : `<my-named-image-target name="6">
                <a-image src="#6-image"></a-image>
              </my-named-image-target>`,
      '7' : `<my-named-image-target name="7">
                <a-image src="#7-image"></a-image>
              </my-named-image-target>`,
      '8' : `<my-named-image-target name="8">
                <a-image src="#8-image"></a-image>
              </my-named-image-target>`,
      '9' : `<my-named-image-target name="9">
                <a-image src="#9-image"></a-image>
              </my-named-image-target>`,
    }
    let html = '';
    for(let i = 0; i < CurrentImageTargets.length; i++){
      let cit = CurrentImageTargets[i];
      if(TargetImageContainers[cit]){
        if(slug == cit){
          html += TargetImageContainers[cit]
        } else {
          html += `<my-named-image-target name="${cit}"></my-named-image-target>`
        }
      }
    }
    return html;
  }

  const TargetImageAssetCode = () => {
    const TargetImageAssets = {
      '1' : `<img id="1-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/1.png">`,
      '2' : `<img id="2-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/2.png">`,
      '3' : `<img id="3-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/3.png">`,
      '4' : `<img id="4-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/4.png">`,
      '5' : `<img id="5-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/5.png">`,
      '6' : `<img id="6-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/6.png">`,
      '7' : `<img id="7-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/7.png">`,
      '8' : `<img id="8-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/8.png">`,
      '9' : `<img id="9-image" crossorigin="anonymous" src="/assets/8thwall-scene-page/target-images/9.png">`,    
    }

    let assets = ``;

    for(let i = 0; i < CurrentImageTargets.length; i++){
      let cit = CurrentImageTargets[i];
      if(cit == slug && TargetImageAssets[cit]){
        assets += TargetImageAssets[cit]
      }
    }
    return assets;
  }

  return (
    <>
      
      {
        (()=> {
            if(arSceneStart){
              if(showTakeButton || showNotFoundPopup) {
                return false
              } else {
                return (
                  <>
                    <div className="camera">
                      <div className="black-bg">
                        <div className="top"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                        <div className="bottom"></div>
                      </div>
                      <div className='camera-msg'>
                          스티커 조각을 찾아 카메라로 비춰보세요.
                      </div>
                      <div className='camera-frame'>
                        <span className='top left'></span>
                        <span className='top right'></span>
                        <span className='bottom left'></span>
                        <span className='bottom right'></span>
                      </div>  
                    </div> 
                  </>
                )
              }
            } else {
                return (
                    <>
                        <section className="page-item-wrap">
                            <div className="box-item-wrap">
                              <div className="btitle">
                                ‘북두칠성’
                              </div>
                                <div className="bmsg">
                                    스티커 조각을 찾아보세요!
                                </div>
                                <div className="bpic">
                                    <img src={(expectedTargetImage && expectedTargetImage.shape_image_url)?expectedTargetImage.shape_image_url:`https://thumbs.dreamstime.com/b/sticker-cartoon-grumpy-boy-creative-150394628.jpg`} />
                                </div>
                              <div className="btn-item-wrap">
                                  <button type="button" onClick={() => setArSceneStart(true)}>확인</button>
                              </div>
                            </div>
                        
                        </section>
                    </>
                )
            }
        })()
      }

      {
        (() => {
          if(showNotFoundPopup){
            return (
              <section className="page-item-wrap">
                <div className="box-item-wrap">
                  <div className="bmsg">
                     찾고 있는 스티커가 아닙니다.
                  </div>
                  <div className="bpic">
                      <img src={(expectedTargetImage && expectedTargetImage.shape_image_url)?expectedTargetImage.shape_image_url:`https://thumbs.dreamstime.com/b/sticker-cartoon-grumpy-boy-creative-150394628.jpg`} />
                  </div>
                  <div className="btn-item-wrap">
                      <button type="button" onClick={() => setShowNotFoundPopup(false)}>다시 찾기</button>
                  </div>
                </div>
            </section>)
          } else {
            return false
          }
        })()
      }

      {
        (() => {
          if (showTakeButton && arSceneStart && !showNotFoundPopup) {
            return <button className='take-it-button' onClick={checkTargetImage}>영상 보고 스티커 조각 가져가기</button>
          } else {
            return false
          }
        })()
      }
      
      <AFrameScene
        sceneHtml={
          `
            <a-scene
              xrextras-gesture-detector
              xrextras-loading
              xrextras-runtime-error
              landing-page
              config-targets="targets: ${CurrentImageTargets.join()}"
              xrweb="disableWorldTracking: true">
              <a-assets>
                ${TargetImageAssetCode()}
              </a-assets>
              
              <a-camera
                position="0 4 10"
                raycaster="objects: .cantap"
                cursor="fuse: false; rayOrigin: mouse;">
              </a-camera>
              
              <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>
              <a-light type="ambient" intensity="1"></a-light>
              
              
              ${TargetImageContainerCode()}

            </a-scene>
          `
        }
        components={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTarget,
          },
          {
            'name': 'rotate-360',
            'val': RotateComponent,
          },
          {
            'name': 'config-targets',
            'val': configTargetsComponent,
          },
        ]}
        primitives={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTargetPrimitive,
          },
        ]}
      />
    </>
  )
})
export {Scene}