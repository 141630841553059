import {BrowserRouter, Routes, Route} from "react-router-dom"
import {useState} from 'react'

import {Home} from './pages/home/home'
import {ARFind} from './pages/home/ARFind'
import {NotFound} from './pages/not-found/notfound'
import {Scene} from './pages/scene/scene'
import {Result} from './pages/result/result'

import './App.css';

function App() {
  const base = '';

  const [showTakeButton, setShowTakeButton] = useState(false)
  const [targetImage, setTargetImage] = useState(null)
  const [arSceneStart, setArSceneStart] = useState(false)
  const [showNotFoundPopup, setShowNotFoundPopup] = useState(false)

  return (
      <BrowserRouter>
        <Routes>
          <Route exact path={`${base}/`} element={<Home />} />
          <Route exact path={`${base}/ARFind/`} element={<ARFind />} />
          
          <Route exact path={`${base}/scene/:slug`} element={<Scene 
                 showTakeButton={showTakeButton} 
                 setShowTakeButton={setShowTakeButton}
                 targetImage={targetImage} 
                 setTargetImage={setTargetImage}
                 arSceneStart={arSceneStart}
                 setArSceneStart={setArSceneStart}
                 showNotFoundPopup={showNotFoundPopup}
                 setShowNotFoundPopup={setShowNotFoundPopup}
               
             />}/>

          <Route exact path={`${base}/result/:slug`} element={<Result />} />
          
          <Route element={<NotFound />} />
        </Routes>
      </BrowserRouter>
  )
}

export default App;
