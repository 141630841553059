import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import { sound } from '@pixi/sound';
import {DataManager} from "./DataManager.ts";

class Button extends PIXI.Container 
{

    textArea : PIXI.Text;

    bg : PIXI.NineSlicePlane;

    constructor(msg:string, s:number = 40) {

        super();
        
        //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["button.png"], 85, 50, 85, 53);
        
        this.bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/button.png"), 85, 50, 85, 53);

        this.addChild(this.bg);

        //super.anchor.set(0.5);
        
        super.interactive = true;
        super.buttonMode = true;
        
        super.on('pointerdown', this.onClickDown);
        super.on('pointerup', this.onClickUp);
        super.on('pointerupoutside', this.onClickUp);
      
        this.UpdatePlane(msg,s );
     }

     public UpdatePlane(text:string, s:number = 30): void 
    {
        let splitted = text.split("\n"); 

        let ww = Math.min(600, text.length * s * 0.8 + 100);
        ww = Math.max(100, ww);
        
        this.bg.width = ww;
        this.bg.height = 109;

        this.bg.x = -this.bg.width /2;
        this.bg.y = -this.bg.height /2;

        this.width= this.bg.width;
        this.height=  this.bg.height;

         this.textArea = new PIXI.Text(text, {
         fontFamily: "NanumBarunGothic",
         fontSize: s,
         fill: "#ffffff",
         stroke: "black",
         strokeThickness: 0,
         letterSpacing : 0,
         align : 'center'
         });
   
         this.textArea.anchor.set(0.5);
         this.textArea.x= 0;
         this.textArea.y= -10;
         super.addChild(this.textArea);
         this.Scale();
     }

      public SetText(msg:string, s:number = 40):void
      {
         super.removeChild(this.textArea);

         this.UpdatePlane(msg,s);
      }

     public DelayActive(t:number)
     {
        super.visible =false;
        //super.visible =true;
        setTimeout(() => {super.visible =true;this.Scale2()}, t);
     }
  
     // 좁더 세개 쿨ㄹㅇ
     public DelayActive2(t:number)
     {
        super.visible =false;
        //super.visible =true;
        setTimeout(() => {super.visible =true;this.Scale()}, t);
     }

     public onClickDown():void
     {
        super.scale.x = 1.25;
        super.scale.y = 1.25;
     }
    
     public onClickUp():void
     {
        super.scale.x = 1;
        super.scale.y = 1;

        sound.play('click');
     }
    

     public Scale(): void 
     {
         super.scale.x = 0.01;
         super.scale.y = 0.01;
         //var THIS = this;
         //const example = ease.add(THIS, {  scale: 1  } , { reverse: false, duration: 2000, ease: 'easeOutElastic' });
 
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic' });
         
         //example.on('each', () => console.log(''));
         //example.once('complete', () => console.log(''));
     }

     public Scale2(): void 
     {
         super.scale.x = 0.01;
         super.scale.y = 0.01;
         //var THIS = this;
         //const example = ease.add(THIS, {  scale: 1  } , { reverse: false, duration: 2000, ease: 'easeOutElastic' });
 
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 300, ease: 'easeOutBack' });
         
         //example.on('each', () => console.log(''));
         //example.once('complete', () => console.log(''));
     }
} 


export {Button}

