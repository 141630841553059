import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { ColorButton } from "./ColorButton.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { TextBox } from "./TextBox.ts";
import { PopupErrorZone } from "./PopupErrorZone.ts";



class SceneZone1 extends Scene
{
    
    constructor() 
    {
        super();


        const urlParameter = window.location.search;
        const urlParams = new URLSearchParams(urlParameter);
        let IsDebug = urlParams.get("debug");
        if ( IsDebug=="true")
        {
           ModelManager.instance().setData("ardebug","true");
        }else{
           ModelManager.instance().setData("ardebug","false");
        }
        
        let IsSkip = urlParams.get("skip");
        if ( IsSkip=="true")
        {
           ModelManager.instance().setData("arskip","true");
        }else{
           ModelManager.instance().setData("arskip","false");
        }


        let bg = new PIXI.Graphics();
        bg.beginFill(0xffffff);
        bg.drawRect(0,0,Config.SCREEN_WIDTH,Config.SCREEN_HEIGHT);
        bg.endFill();
        
        super.addChild(bg);

        let backButton = new Button("",30 );
        backButton.x = 100/2;
        backButton.y = 109/2;
        backButton.alpha = 1;
        super.addChild(backButton);

        let image_01_copy=new Image("icon_prev.png");
  
        image_01_copy.x = 0;
        image_01_copy.y = 0; 
        
        backButton.addChild(image_01_copy);


        backButton.on('pointerup', ()=>
        {
          console.log("back");
        });
        
        let textArea = new PIXI.Text("관악 AR체험", {
          fontFamily: "NanumBarunGothics",
          fontSize: 40,
          fill: "#000000",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
          });
    
          textArea.anchor.set(0.5);
          textArea.x= Config.SCREEN_WIDTH/2;
          textArea.y= 50;
          super.addChild(textArea);

        let map_dummy=new Image("img_map.png");

        map_dummy.x = Config.SCREEN_WIDTH/2;
        map_dummy.y = Config.SCREEN_HEIGHT/2; 
        
        super.addChild(map_dummy);

        let img6193=new Image("img6193.png");

        img6193.x = Config.SCREEN_WIDTH/2-47;
        img6193.y = Config.SCREEN_HEIGHT/2 -62; 
        //img6193.ScaleLoop();
        super.addChild(img6193);

         //103 87


         let button = new ColorButton("시작하기",30 );
         button.x = Config.SCREEN_WIDTH/2;
         button.y = Config.SCREEN_HEIGHT -80-52;
         
         super.addChild(button);


         button.on('pointerup', ()=>
         {
          SceneManager.instance().SetScene(SCENE.ITEM1);
         });
         

    }

} 

export {SceneZone1}
