import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";

class SceneEnd extends Scene
{
    TextBox1 : TextBox ;
    TextBox2 : TextBox ;
    TextBox3 : TextBox ;
    

    view1:PIXI.Container;
    view2:PIXI.Container;
    view3:PIXI.Container;

    constructor() 
    {
        super();
      
        let bg  = new Image("bg-03.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);


        this.view1=new PIXI.Container();

        super.addChild(this.view1);

        this.view2=new PIXI.Container();

        this.view2.visible = false;

        super.addChild(this.view2);

        this.view3=new PIXI.Container();

        this.view3.visible = false;

        super.addChild(this.view3);


        // let title = new PIXI.Text("지금까지 너무 잘했어!", {
        //   fontFamily: "MaplestoryLight",
        //   fontSize: 60,
        //   fill: "#ffffff",
        //   stroke: "black",
        //   strokeThickness: 0,
        //   letterSpacing : 0,
        //   align : 'center'
        //   });
    
        //   title.anchor.set(0.5);
        //   title.x= Config.SCREEN_WIDTH/2;
        //   title.y= 365 + 49/2;
        //   this.view1.addChild(title);



       //this.TextBox1 = new TextBox("아직 마을이 침략으로 인해\n어수선 하지만,",30,false);
       this.TextBox1 = new TextBox("지금까지 너무 잘했어!\n아직 마을이 침략으로 인해\n어수선 하지만,",30);
       this.TextBox1.x =Config.SCREEN_WIDTH/2;
       this.TextBox1.y = 454 + 73/2;

       this.view1.addChild(this.TextBox1);

       let centerImagee = new Image("image-outdoor1.png");
       centerImagee.x = 28+565/2;
       centerImagee.y = 486+396/2;
       centerImagee.visible =false;
       this.view1.addChild(centerImagee);


       let dandiimage = new Image("cha.png");
       dandiimage.x = 254+339/2;
       dandiimage.y = 502+351/2;
       dandiimage.MoveSin(1, 30); 
       this.view1.addChild(dandiimage);
       ////////////////

      //this.TextBox2 = new TextBox("이제 보고 있는 스마트폰은\n주머니나 가방에 넣어도 돼~!!",30,false);
      this.TextBox2 = new TextBox("이제 보고 있는 스마트폰은\n주머니나 가방에 넣어도 돼~!!",30,false);
      this.TextBox2.x =Config.SCREEN_WIDTH/2;
      //this.TextBox2.y = 454 + 73/2;
      this.TextBox2.y = 314 + 73/2;
      this.view2.addChild(this.TextBox2);

      let centerImagee2 = new Image("image-pocket.png");
      centerImagee2.x =  Config.SCREEN_WIDTH/2;
      centerImagee2.y = 480+245/2;
      
      this.view2.addChild(centerImagee2);

      let arraow1 = new Image("end-image-arrow.png");
      arraow1.x = 198+33/2;
      arraow1.y = 441+66/2;
      this.view2.addChild(arraow1);

      let arraow2 = new Image("end-image-arrow.png");
      arraow2.x = 370+33/2;
      arraow2.y = 491+66/2;
      this.view2.addChild(arraow2);

/////////

    this.TextBox3 = new TextBox("그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!",30,false);
    this.TextBox3.x =Config.SCREEN_WIDTH/2;
    //this.TextBox3.y = 454 + 73/2;
    this.TextBox3.y = 314 + 73/2;

    this.view3.addChild(this.TextBox3);


    let dandiimage2 = new Image("cha.png");
    dandiimage2.x = 254+339/2;
    dandiimage2.y = 502+351/2;
    dandiimage2.MoveSin(1, 30); 
    this.view3.addChild(dandiimage2);

       //////////



      setTimeout(()=>{
        //title.visible =false;
        centerImagee.visible =true;
        this.TextBox1.init("AR 디오라마로\n비행 산책도 하고!");
        this.TextBox1.y = 314 + 73/2;
       }, 5000);

      setTimeout(()=>{
        //centerImagee.texture = DataManager.instance().sheet.textures["image-outdoor2.png"];
        centerImagee.texture = PIXI.Texture.from("./assets/images/scene/image-outdoor2.png");
        

        this.TextBox1.init("VR 옥공방에서\n직접 목걸이도 만들어보고");
        this.TextBox1.y = 314 + 73/2;
       }, 9000);

       setTimeout(()=>{
        // /centerImagee.texture = DataManager.instance().sheet.textures["image-outdoor3.png"];

        centerImagee.texture = PIXI.Texture.from("./assets/images/scene/image-outdoor3.png");

        this.TextBox1.init("야외 움집도 직접 들어가서\n다른 도움이 필요한지 확인해보자!!");
        this.TextBox1.y = 314 + 73/2;
       }, 12000);

       setTimeout(()=>{

        this.view1.visible = false;
        this.view2.visible = true;
        this.view3.visible = false;

        setInterval( ()=>{ this.toggle() }  , 5000);
        
       }, 17000);
       
    }
    
    public toggle():void
    {
      this.view1.visible = false;
      this.view2.visible = !this.view2.visible;
      this.view3.visible = !this.view3.visible;
      if ( this.view2.visible ==true)
      {
           this.TextBox2.init("이제 보고 있는 스마트폰은\n주머니나 가방에 넣어도 돼~!!");
      }

      if ( this.view3.visible ==true)
      {
            this.TextBox3.init("그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!");
           
      }
    }

    
} 

export {SceneEnd}
