import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { TextBox } from "./TextBox.ts";
import { PopupErrorZone } from "./PopupErrorZone.ts";
import { sound } from '@pixi/sound';
import {EffectAnimation} from "./EffectAnimation.ts";

class SceneMovieItem1 extends Scene
{
    texture:PIXI.Texture;
    texture2:PIXI.Texture;

    constructor() 
    {
        super();

        this.loader.add("assets/star.json")
        .load(
          ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {
        sound.stopAll();

        let darkImage = new PIXI.Graphics();
        darkImage.beginFill(0x000000);
        darkImage.drawRect(-Config.SCREEN_WIDTH,0,Config.SCREEN_WIDTH*3, Config.SCREEN_HEIGHT);
        darkImage.endFill();
        super.addChild(darkImage);

        let playButton=new Image("bg1.png");
  
        playButton.interactive = true;
        playButton.buttonMode = true;
        super.addChild(playButton);

        let btnPlay=new Image("btn-play.png");
        btnPlay.x = 0;
        btnPlay.y = 0; 
        playButton.addChild(btnPlay);

        playButton.on('pointerup', ()=>
        {
          playButton.visible = false;
          this.next();
        }
        );
    }

    next():void
    {

        this.texture = PIXI.Texture.from("assets/mp4/item1a.mp4");

        const videoSprite = new PIXI.Sprite(this.texture);
    
        // Stetch the fullscreen
        videoSprite.x=Config.SCREEN_WIDTH/2;
        videoSprite.y=Config.SCREEN_HEIGHT/2;
        videoSprite.anchor.x = 0.5;
        videoSprite.anchor.y = 0.5;  
        videoSprite.width = Config.SCREEN_WIDTH;
        videoSprite.height = Config.SCREEN_HEIGHT;

        super.addChild(videoSprite);

            this.texture.baseTexture.resource.source.addEventListener('ended', (event) => {

              //sound.play('item1');

              let dandiimage = new EffectAnimation(this.GetTexture("assets/star.json"),1 );
              dandiimage.x = 380;
              dandiimage.y = 210; 
              dandiimage.scale.x=1.5;
              dandiimage.scale.y=1.5;
              
              dandiimage.interactive = true;
              dandiimage.buttonMode = true;
             
              super.addChild(dandiimage);

              dandiimage.on('pointerup', ()=>
              {
                dandiimage.visible = false;
                //UI.visible = false;
      
                  this.texture2 = PIXI.Texture.from("assets/mp4/item1b.mp4");
      
                  // create a new Sprite using the video texture (yes it's that easy)
                  const videoSprite = new PIXI.Sprite(this.texture2);
              
                  // Stetch the fullscreen
                  videoSprite.x=Config.SCREEN_WIDTH/2;
                  videoSprite.y=Config.SCREEN_HEIGHT/2;
                  videoSprite.anchor.x = 0.5;
                  videoSprite.anchor.y = 0.5;  
                  videoSprite.width = Config.SCREEN_WIDTH;
                  videoSprite.height = Config.SCREEN_HEIGHT;
      
                  super.addChild(videoSprite);

                  this.texture2.baseTexture.resource.source.addEventListener('ended', (event) => {
                    SceneManager.instance().SetScene(SCENE.NEXTITEM); // 
                    });
               
              });


              });
    
    }

} 

export {SceneMovieItem1}
