import * as PIXI from "pixi.js";
import { WebfontLoaderPlugin } from "pixi-webfont-loader";
import { sound } from '@pixi/sound';
import {DataManager} from "../../lib/html5story/DataManager.ts";
import {Scene} from "./Scene.ts";
import { SceneLoading} from "./SceneLoading.ts";
import { SceneEnd } from "./SceneEnd.ts";
import { SceneNextItem} from "./SceneNextItem.ts"; // 다음 곳으로
import { SceneTarget} from "./SceneTarget.ts"; // ar 타겟
import { SceneZone1} from "./SceneZone1.ts";
import { SceneNowItem} from "./SceneNowItem.ts";
import { SceneMovie} from "./SceneMovie.ts";
import { SceneMovie2} from "./SceneMovie2.ts";
import { SceneItem1} from "./SceneItem1.ts";
import { SceneItem2} from "./SceneItem2.ts";
import { Config } from "./Config.ts";
import { SceneMovieItem1} from "./SceneMovieItem1.ts";
import { SceneMovieItem2} from "./SceneMovieItem2.ts";
import { SceneMovieItem3} from "./SceneMovieItem3.ts";

import { SceneMovieItem4} from "./SceneMovieItem4.ts";
import { SceneMovieItem5} from "./SceneMovieItem5.ts";


PIXI.Loader.registerPlugin(WebfontLoaderPlugin);
//
// alex-productions-happy-and-fun-background-music     유물화면
// game-victory-sound-effect    성공시
enum SCENE {
    LOADING,
    END,
    TARGET, //
    NOWITEM,// 
    NEXTITEM,// 
    ZONE1,
    MOVIE,
    ITEM1,
    ITEM2,
    MOVIEITEM1,
    MOVIEITEM2,
    MOVIEITEM3,
    MOVIEITEM4,
    MOVIEITEM5,
    MOVIEITEM6,
    MOVIEITEM7,
    MOVIEITEM8,
}

class SceneManager {

    private static _instance: SceneManager = null;
    public root: PIXI.Container = null;
    public scene: Scene = null;
    public app:PIXI.Application = null;
    
    darkImage:PIXI.Graphics;
    whiteImage:PIXI.Graphics;

    public static instance(): SceneManager {
        if (SceneManager._instance === null) {
            SceneManager._instance = new SceneManager();
        }
        return SceneManager._instance;
    }

    constructor() {
        if (SceneManager._instance) {
            throw new Error("Error: Config instead of new.");
        }
        SceneManager._instance = this;

        this.root = new PIXI.Container();
        // 중앙정렬
        this.root.x=(window.innerWidth -  Config.SCREEN_WIDTH * window.innerHeight/  Config.SCREEN_HEIGHT   ) /2 / window.innerHeight * Config.SCREEN_HEIGHT;

        /////
        
        this.darkImage = new PIXI.Graphics();
        this.darkImage.beginFill(0x000000);
        this.darkImage.drawRect(-(window.innerWidth -  Config.SCREEN_WIDTH*  window.innerHeight/  Config.SCREEN_HEIGHT   ) /2,0, (window.innerWidth - 640*  window.innerHeight/  Config.SCREEN_HEIGHT   ) /2, Config.SCREEN_HEIGHT);
        this.darkImage.drawRect( Config.SCREEN_WIDTH,0, (window.innerWidth -  Config.SCREEN_WIDTH*  window.innerHeight/  Config.SCREEN_HEIGHT   ) /2,  Config.SCREEN_HEIGHT);
         this.darkImage.endFill();
        
         this.root.addChild(this.darkImage);

        this.SetScene(SCENE.LOADING);

         sound.add('bgm', './assets/bgm.mp3');
         sound.add('bgm_item', './assets/next.mp3');
         sound.add('click', './assets/click.mp3');
         sound.add('item1', './assets/item1.mp3');
         sound.add('item2', './assets/item2.mp3');
         sound.add('item5', './assets/item5.mp3');
        sound.play('bgm',{ loop:true, volume:0.3 });
    }

    public Init(play:SCENE):void{

            const app = new PIXI.Application({ backgroundColor: 0x000000,
                width: window.innerWidth ,
                height:window.innerHeight,
                antialias:true,
            });

            app.stage.scale.set( window.innerHeight/ Config.SCREEN_HEIGHT );

            document.body.appendChild(app.view);
            let r = Math.random();

            var THIS=this;
            function setup() {
                DataManager.instance().Init();
                app.stage.addChild(SceneManager.instance().root);
                THIS.app = app;

                THIS.SetScene(play);
            }

            DataManager.instance().loader
            //.add({ name: "MaplestoryLight", url: "./assets/MaplestoryLight.ttf" })
            .add({ name: "NanumBarunGothic", url: "./assets/NanumBarunGothic.ttf" })
            .load(setup);

            console.log(window.location.href);
    }

    public SetScene(s:SCENE):void
    {
        console.log("scene:"+SCENE[s]);
        if (this.scene!=null)
        {
            this.root.removeChild(this.scene);
        }

        switch(s)
        {
            case SCENE.LOADING:
                this.scene = new SceneLoading();
                break;
            case SCENE.TARGET:
                this.scene = new SceneTarget();
                break;
            case SCENE.END:
                this.scene = new SceneEnd();
                break;
            case SCENE.NEXTITEM:
                this.scene = new SceneNextItem();
                break;
            case SCENE.NOWITEM:
                this.scene = new SceneNowItem();
                break;
            case SCENE.ZONE1:
                this.scene = new SceneZone1();
                break;
            case SCENE.MOVIEITEM1:
               this.scene = new SceneMovieItem1();
                break;
            case SCENE.MOVIEITEM2:
               this.scene = new SceneMovieItem2();
                break;
            case SCENE.MOVIEITEM3:
                this.scene = new SceneMovieItem3();
                break;
            case SCENE.MOVIEITEM4:
                this.scene = new SceneMovieItem4();
                break;
            case SCENE.MOVIEITEM5:
                this.scene = new SceneMovieItem5();
                break;
            case SCENE.ITEM1:
                this.scene = new SceneItem1();
                break;
            case SCENE.ITEM2:
                this.scene = new SceneItem2();
                break;
        }

        this.root.addChildAt(this.scene, 0);
    }

    public goURL(url:string):void
    {
        const urlParameter = window.location.search;
        window.location.href = url + urlParameter;


    // //const url = window.location.href;
    // const urlParameter = window.location.search;
    // const urlParams = new URLSearchParams(urlParameter);
    // console.log(urlParams.get("a"));
    // // for(const key of keys) {
    // //   console.log(key);
    // // }
    // // const values = urlParams.values();

    // // for(const value of values) {
    // //     console.log(value);
    // //   }
    }

} 

export {SceneManager, SCENE}